import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public EST_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
  public USERTOKEN: any = '';
  public selectedStoreId = null;
  public selectedStoreUid = null;
  public loggedInUserDetails = null;
  public loggedInUserRole = null;
  // public WEB_SITE_URL = environment.WEBSITE_URL;
  public FIREBASE_TOKEN = '';
  constructor(
  ) {
    this.loggedInUserRole = this.getLoggedInUserRole();
  }

  /**
  * Marks all controls in a form group as touched
  * @param formGroup - The form group to touch
  */
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Returns String containing list of error messages to be displayed in a single string(toast)
   * @param errors Errors Object Received from API Response
   */
  getErrorMessageList(errors) {
    const errList = [];
    errors.forEach(error => {
      errList.push(error['message']);
    });

    return errList;
  }

  /**
   * Set Selected Store
   */

  setStore(storeId) {
    this.selectedStoreId = storeId;
    localStorage.setItem('selectedStore', this.selectedStoreId);
  }
  setStoreUid(storeUid) {
    this.selectedStoreUid = storeUid;
    localStorage.setItem('selectedStoreUid', this.selectedStoreUid);
  }

  getLocalStore() {
    return localStorage.getItem('selectedStore');
  }
  getLocalStoreUid() {
    return localStorage.getItem('selectedStoreUid');
  }

  getLoggedInUserDetails() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.loggedInUserDetails = loggedInUserDetails;
    return loggedInUserDetails;
  }

  getLoggedInUserRole() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    let role = null;
    if (loggedInUserDetails) {
      role = loggedInUserDetails.roles[0];
    }
    return role;
  }

  clearLocalStorage() {
    localStorage.clear();
    localStorage.removeItem('selectedStore');
    localStorage.removeItem('selectedStoreUid');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('loggedInUserDetails');
    localStorage.removeItem('fbToken');
  }

  formatDate(date) {
    if (date) {
      if (moment(date).isValid) {
        return moment(date).toDate();
      } else {
        return date;
      }
    } else {
      return moment().toDate();
    }
  }

  setFirebaseToken() {
    this.FIREBASE_TOKEN = this.getFireBaseToken();
  }

  getFireBaseToken() {
    return localStorage.getItem('fbToken');
  }

  getCuisines() {
    const list = [ 'Breakfast and brunch', 'Indian', 'Fast food', 'Pizza', 'Chinese', 'Bakery', 'Healthy', 'Dessert',
                   'Caribbean', 'Sushi', 'Bubble tea', 'American', 'Korean', 'Italian', 'Halal', 'Asian', 'Mexican',
                   'Vegetarian', 'Vegan', 'Japanese', 'Thali', 'Vietnamese', 'Pakistani', 'Middle eastern', 'Jamaican',
                   'Pub', 'Portuguese', 'Juice and smoothies', 'Cafe', 'Deli', 'Gluten free', 'French', 'West Indian',
                   'Cantonese', 'Canadian', 'German' ];
    return list;
  }

  // -------------------------------- Letters only method --------------------------------
  latterOnly(event): boolean{
    console.log(event);
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        console.log(charCode);
        return false;
    }
    return true;
  }

  // -------------------------------- Number only method --------------------------------
  numberOnly(event): boolean{
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

}
